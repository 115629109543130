import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { HeroSection as HeroSectionCustom } from './styles';

import LogoGeneroso from '@/images/paginas/seja-generoso/seja-generoso-logo.svg';

const HeroSection = (): JSX.Element => {
  return (
    <HeroSectionCustom>
      <StaticImage
        src="../../../../images/paginas/seja-generoso/seja-generoso-bg.png"
        alt="Imagem de fundo com várias pessoas em ação beneficente."
        placeholder="blurred"
        quality={100}
        transformOptions={{ fit: 'fill' }}
      />
      <img src={LogoGeneroso} alt="Logo do Transporte Generoso" />
    </HeroSectionCustom>
  );
};

export default HeroSection;
