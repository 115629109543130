/* eslint-disable */
/* prettier-ignore */
import React from 'react';

import { SectionThree } from './styles';

import { Link } from 'gatsby';

const SectionThreeComponent = (): JSX.Element => {
  return (
    <SectionThree>
      <p>
        Por aqui você vira nosso parceiro nas causas sociais, um simples ato de
        generosidade é capaz de ajudar muitas pessoas, conheça a unidade
        Generoso mais próxima e junte-se a nós!
      </p>
       <a href="https://cliente.generoso.com.br/unidades" target='_blank' rel="noreferrer">Conheça nossas unidades</a>
    </SectionThree>
  );
};

export default SectionThreeComponent;
