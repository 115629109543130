import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  SectionTwoLeft,
  SectionTwoRight,
  SectionTwo,
  SectionTwoDonation,
} from './styles';

import HeartIcon from '@/images/icons/icon-heart.svg';

const SectionTwoComponent = (): JSX.Element => {
  return (
    <SectionTwo>
      <SectionTwoLeft>
        <h2>Quer ser Generoso também?</h2>
        <h3>
          Nós estamos por dentro de tudo que acontece nas Regiões onde marcamos
          presença, e prezamos o bem-estar da comunidade.
        </h3>
        <p>
          Ao longo do ano coletamos vários itens que são doados por nossos
          colaboradores, clientes e população.
        </p>
        <p>
          Nas campanhas de doações, você pode organizar e separar alguns itens
          como:
        </p>
        <SectionTwoDonation>
          <div>
            <img
              src={HeartIcon}
              alt="Icone de coração ao lado de opção de doação"
            />
            <p>Alimentos não perecíveis</p>
          </div>
          <div>
            <img
              src={HeartIcon}
              alt="Icone de coração ao lado de opção de doação"
            />
            <p>Agasalhos</p>
          </div>
          <div>
            <img
              src={HeartIcon}
              alt="Icone de coração ao lado de opção de doação"
            />
            <p>Itens de higiene pessoal</p>
          </div>
          <div>
            <img
              src={HeartIcon}
              alt="Icone de coração ao lado de opção de doação"
            />
            <p>Livros e brinquedos</p>
          </div>
        </SectionTwoDonation>
      </SectionTwoLeft>
      <SectionTwoRight>
        <StaticImage
          src="../../../../images/paginas/seja-generoso/section-two.png"
          alt="Pessoa guardando doações em um caminhão"
          placeholder="blurred"
          quality={100}
          width={561}
        />
      </SectionTwoRight>
    </SectionTwo>
  );
};

export default SectionTwoComponent;
