import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const SectionTwo = styled.section`
  display: flex;
  margin-top: 80px;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1146px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 1114px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    margin-top: 92px;
  }

  @media (max-width: 650px) {
    align-items: flex-start;
  }

  @media (max-width: 500px) {
    margin-top: 40px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const SectionTwoLeft = styled.div`
  width: 100%;
  max-width: 485px;

  display: flex;
  flex-direction: column;

  h2 {
    color: ${colors.blue400};
    font-size: 48px;
    font-weight: 700;

    line-height: 60px;
  }

  h3 {
    color: ${colors.blue300};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;

    margin-top: 8px;
  }

  p {
    color: ${colors.gray600};
    font-size: 16px;
    margin-top: 24px;
    line-height: 20px;
  }

  @media (max-width: 1140px) {
    margin-top: 40px;
  }

  @media (max-width: 500px) {
    margin-top: 35px;

    h3 {
      margin-top: 16px;
    }

    p {
      margin-top: 16px;
    }

    p + p {
      margin-top: 24px;
    }
  }

  @media (max-width: 320px) {
    margin-top: 16px;
    h2 {
      font-size: 36px;
      line-height: 48px;
    }

    h3 {
      font-size: 16px;
      line-height: 22px;
    }

    p {
      font-size: 12px;

      & + p {
        margin-top: 16px;
      }
    }
  }
`;

export const SectionTwoRight = styled.div`
  .gatsby-image-wrapper {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
  }
`;

export const SectionTwoDonation = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 8px;

  div {
    display: flex;
    align-items: center;

    margin-top: 16px;
  }

  p {
    margin-left: 20px;
    margin-top: 0;

    font-weight: 700;
  }

  img {
    width: 20.3px;
    height: 17.8px;
  }

  @media (max-width: 500px) {
    margin-top: 16px;
  }

  @media (max-width: 320px) {
    p {
      margin: 0;
      margin-left: 10px;
    }
  }
`;
