import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1381px;

  background-color: ${colors.blue400};

  .gatsby-image-wrapper {
    background-color: ${colors.blue400};
    opacity: 0.3;

    img {
      width: 100%;
      height: 100%;

      mix-blend-mode: luminosity;
    }
  }

  img {
    position: absolute;
  }

  @media (max-width: 1200px) {
    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 490px;
      }
    }
  }

  @media (max-width: 690px) {
    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 400px;
      }
    }
  }
`;
