import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import BreadCrumbs from '@/components/BreadCrumbs';

import HeroSection from '@/components/Pages/SejaGeneroso/HeroSection';
import SectionOne from '@/components/Pages/SejaGeneroso/SectionOne';
import SectionTwo from '@/components/Pages/SejaGeneroso/SectionTwo';
import SectionThree from '@/components/Pages/SejaGeneroso/SectionThree';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  BreadCrumbsWrapper,
} from '@/styles/pages/seja-generoso';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'A Generoso' },
  { name: 'Seja Generoso' },
];

const SejaGenerosoPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "seja-generoso" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;
  return (
    <>
      <SEO
        pageTitle="Seja Generoso"
        title={SEOData.title}
        description={SEOData.description}
        image={SEOData.image}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <HeroSection />
            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>
            <SectionWrapper>
              <SectionOne />
            </SectionWrapper>
            <SectionWrapper>
              <SectionTwo />
            </SectionWrapper>
            <SectionWrapper>
              <SectionThree />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default SejaGenerosoPage;
