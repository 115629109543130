import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;

  margin-top: 92px;
  margin-bottom: 92px;

  p {
    max-width: 548px;
  }

  a {
    background-color: ${colors.blue400};

    outline: none;

    border: none;

    border-radius: 8.4px;
    height: 40px;

    width: 100%;

    color: white;

    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 263px;

    img {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 500px) {
    margin-top: 60px;
    margin-bottom: 40px;

    margin-left: 16px;
    margin-right: 16px;

    align-items: flex-start;

    text-align: left;
  }

  p {
    margin-bottom: 0;
  }

  a {
    margin-top: 32px;
  }

  @media (max-width: 320px) {
    margin-top: 32px;
    p {
      font-size: 12px;
    }
  }
`;
