import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const SectionOne = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1146px;

  margin-top: 92px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 1114px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 92px;
  }

  @media (max-width: 650px) {
    align-items: flex-start;
  }

  @media (max-width: 500px) {
    margin-top: 40px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const SectionOneLeft = styled.div`
  .gatsby-image-wrapper {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
  }

  @media (max-width: 1114px) {
    margin-top: 16px;
  }

  @media (max-width: 500px) {
    margin-top: 15px;
  }
`;

export const SectionOneRightTop = styled.div`
  text-align: center;

  width: 100%;
  max-width: 464px;

  h2 {
    color: ${colors.blue400};
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
  }

  h3 {
    color: ${colors.blue300};
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }

  display: none;

  @media (max-width: 1114px) {
    display: block;

    h3 {
      margin-top: 16px;
    }
  }

  @media (max-width: 650px) {
    text-align: start;
    align-self: flex-start;

    h3 {
      max-width: 396px;
      margin-top: 15px;
    }
  }

  @media (max-width: 320px) {
    h2 {
      font-size: 36px;
      line-height: 48px;
    }

    h3 {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export const SectionOneRightBottom = styled.div`
  text-align: center;

  width: 100%;
  max-width: 464px;
  p {
    color: ${colors.gray600};
    font-size: 16px;

    margin-top: 15px;
  }

  p + p {
    margin-top: 24px;
  }

  display: none;

  @media (max-width: 1114px) {
    display: block;
  }
  @media (max-width: 650px) {
    text-align: start;
  }

  @media (max-width: 320px) {
    p {
      font-size: 12px;

      & + p {
        margin-top: 16px;
      }
    }
  }
`;

export const SectionOneRight = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 465px;

  h2 {
    color: ${colors.blue400};
    font-size: 48px;
    font-weight: 700;

    line-height: 60px;
  }

  h3 {
    color: ${colors.blue300};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;

    margin-top: 8px;
  }

  p {
    color: ${colors.gray600};
    font-size: 16px;
    margin-top: 24px;
    line-height: 20px;
  }

  @media (max-width: 1114px) {
    display: none;
  }
`;
