import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  SectionOne,
  SectionOneLeft,
  SectionOneRightTop,
  SectionOneRightBottom,
  SectionOneRight,
} from './styles';

const SectionOneComponent = (): JSX.Element => {
  return (
    <SectionOne>
      <SectionOneRightTop>
        <h2>Seja Generoso</h2>
        <h3>
          A generosidade está no nosso DNA e é um dos nossos valores mais
          importantes.
        </h3>
      </SectionOneRightTop>

      <SectionOneLeft>
        <StaticImage
          src="../../../../images/paginas/seja-generoso/section-one.png"
          alt="Pessoas segurando uma caixa em frente ao caminhão"
          placeholder="blurred"
          quality={100}
        />
      </SectionOneLeft>

      <SectionOneRightBottom>
        <p>
          A Generoso surgiu com o lema: ‘‘Ajudamos uns aos outros’’, a
          responsabilidade social faz parte da nossa identidade, da nossa
          história, quem nos conhece sabe do nosso compromisso com a sociedade.
        </p>

        <p>
          Estamos sempre atentos para ajudar a quem mais precisa.
          <b> #SEJAGENEROSO</b>
        </p>
      </SectionOneRightBottom>

      <SectionOneRight>
        <h2>Seja Generoso</h2>

        <h3>
          A generosidade está no nosso DNA e é um dos nossos valores mais
          importantes.
        </h3>

        <p>
          A Generoso surgiu com o lema: ‘‘Ajudamos uns aos outros’’, a
          responsabilidade social faz parte da nossa identidade, da nossa
          história, quem nos conhece sabe do nosso compromisso com a sociedade.
        </p>

        <div>
          <p>
            Estamos sempre atentos para ajudar a quem mais precisa.
            <b> #SEJAGENEROSO</b>
          </p>
        </div>
      </SectionOneRight>
    </SectionOne>
  );
};

export default SectionOneComponent;
